html {
  -webkit-tap-highlight-color: transparent;
  scroll-behavior: smooth;
  @media (min-width: 768px) {
    cursor: none;
  }
}

body{
overflow-x: hidden;
}

.button-bg-color {
  background-color: #EBE9DB;
}

.background-blur {
backdrop-filter: blur(7.5px);
}

p#target > div.word, p#target > span > div.word {
line-height: 1.13;
/* border: solid 1px black; */
overflow: hidden;
}

button#heroButton {
transform: scale(0);
transform-origin: 50% 50%;
}

button#heroButton > span.text-wrapper {
/* text wrapper */
display: inline-block;
width: 0px;
overflow: hidden;

}

button#heroButton > span > span.text {
/* text */
display: inline-block;
transform: translateY(24px);
white-space: nowrap;
}

button#heroButton > div.heroButtonSvg {
/* svg */
width: 0px;
overflow: hidden;
transform: translateX(-8px);
opacity: 0;
}

button {
cursor: none;
}

a {
cursor: none;
}


