@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Inter-Black;
  src: url(./assets/fonts/Inter-Black.woff2);
}
@font-face {
  font-family: Inter-Bold;
  src: url(./assets/fonts/Inter-Bold.woff2);
}
@font-face {
  font-family: Inter-Semibold;
  src: url(./assets/fonts/Inter-SemiBold.woff2);
}
@font-face {
  font-family: Inter-Medium;
  src: url(./assets/fonts/Inter-Medium.woff2);
}
@font-face {
  font-family: Inter-Regular;
  src: url(./assets/fonts/Inter-Regular.woff2);
}
@font-face {
  font-family: AvantGarde-Demi;
  src: url(./assets/fonts/AVGARDD_2.woff);
}
@font-face {
  font-family: AvantGarde-DemiOblique;
  src: url(./assets/fonts/AVGARDDO_2.woff);
}
@font-face {
  font-family: AvantGarde-Book;
  src: url(./assets/fonts/AVGARDN_2.woff);
}
@font-face {
  font-family: Times-New-Roman-Italic;
  src:  url(./assets/fonts/times\ new\ roman\ italic.ttf)
}
@font-face {
  font-family: NotoSans-Bold;
  src: url(./assets/fonts/NotoSansJavanese-Bold.woff);
}
@font-face {
  font-family: Montserrat-Medium;
  src: url(./assets/fonts/Montserrat-Medium.woff);
}