
/* ---------- card styles ---------- */
.card {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.card-cover-container {
	/* position: absolute;
	inset: 0;
	overflow: hidden;
	z-index: 1; */
}

.card-cover {
	width: 100%;
	height: 180%;
	object-fit: cover;
	position: absolute;
	bottom: 0;
	left: 0;
}
