.gallery-wrap {
  // background-color: #d53f41;
  // margin-left: -5vw;
  // margin-right: -5vw;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}




.gallery {
  // height: 100vh;
  // position: sticky;
  padding: 12vh 0;
  width: 400%;
  display: flex;
  flex-wrap: nowrap;

  .gallery-item {
    width: 100%;
    height: 100%;
    position: relative;
    will-change: transform;
  }

  .gallery-item-info {
    position: absolute;
    bottom: 30%;
    z-index: 1;
    transform: translateX(-55%);
    // color: darkBeige;
    // color: white;

    .gallery-info-title {
      line-height: 6vw;
      font-family: "Bai Jamjuree";
      font-weight: 600;

      font-size: 6vw;
      -webkit-font-smoothing: antialiased;
    }

    .gallery-info-subtitle {
      position: relative;
      line-height: 6vw;
      color: transparent;
      font-family: "Bodoni Moda";
      font-weight: 400;
      font-size: 6vw;
      -webkit-font-smoothing: antialiased;
      -webkit-text-stroke: 2px #dbd8d6;
    }

    .gallery-info-category {
      position: relative;
      line-height: 24px;
      font-family: "Bai Jamjuree";
      font-weight: 400;
      font-size: 24px;
      -webkit-font-smoothing: antialiased;
      margin-top: 2vh;
    }
  }

  .gallery-item-wrapper {
    aspect-ratio: 16/9;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 55% 15%;
    width: 100vw;

    &.is-reveal {
      .gallery-item-image {
        transform: scale(1);
        filter: none;
      }
    }
  }

  .gallery-item-image {
    background-size: cover;
    background-position: center;
    transform-origin: center;
    width: 55vw;
    height: 65vh;
    will-change: transform;
    transform: scale(0.7);
    transition: all 1.5s cubic-bezier(0.77, 0, 0.175, 1);
    filter: grayscale(100%) sepia(20%) brightness(80%);
  }
}