// .active {
//     // fixed w-full top-0 z-30
//     position: fixed;
//     width: 100%;
//     top: 0px;
//     z-index: 30;
//     transition: 0.3s linear;
//     background-color: white;
    
// }
.white-bg {
    background-color: white;
}

#navbar {
    transition: 0.3s linear;
}

.navbar-small {
    padding: 0.1rem 0.25rem;
    transition: 0.3s linear;
}


@media screen and (max-width: 400px) {
    #navbar {
        padding: 0.1rem 0.25rem;
    }
}